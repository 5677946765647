import axios from '@/util/axios';
// import qs from "qs";

function salvarInsumos (produto_id, insumos) {
  return axios.post('/produtos/' + produto_id + '/insumos/salvar', insumos)
  .then(response => response.data);
}

function carregarInsumos (produto_id) {
  return axios.get('/produtos/' + produto_id + '/insumos')
    .then(response => response.data);
}

export default {
  salvarInsumos,
  carregarInsumos,
}